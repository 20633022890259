<template>
  <div class="columns">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-3">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-container v-if="offers && offers.length === 0">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8">
                <div class="text-center">
                  <h2 class="title is-3 mb-2">У вас не было предложений</h2>
                  <b-button @click="$router.push('/orders/search')" type="is-success is-light">
                    Найти заказы
                  </b-button>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row v-for="(item, index) in offers" :key="item.id">
              <v-card class="w-100 mb-3" :class="'w-100 mb-3 no-gradient-block-' + (index % 4 + 1)">
                <v-card-text class="pb-5">
                  <div class="pa-3 pb-0">
                    <v-row class="mb-2">
                      <v-col class="pa-0">
                        <h1 class="title is-6 ml-3">{{ dateText(item.time) }}</h1>
                      </v-col>
                      <b-taglist>
                        <b-tag rounded v-if="item.status === false" type="is-primary">Не выбран</b-tag>

                        <b-tag rounded v-else-if="item.order.status === 'created'" type="is-primary">Создан</b-tag>
                        <b-tag rounded v-else-if="item.order.status === 'agreed'" type="is-info">Подтвержден</b-tag>
                        <b-tag rounded v-else-if="item.order.status === 'completed'" type="is-success">Доставлен</b-tag>
                        <b-tag rounded v-else-if="item.order.status === 'in_progress'" type="is-warning">В пути</b-tag>
                        <b-tag rounded v-else-if="item.order.status === 'cancelled'" type="is-danger">Отменён</b-tag>
                      </b-taglist>
                    </v-row>

                    <h3 class="title is-6">Цена</h3>
                    <p>{{ item.price }}</p>

                    <div @click="toggleCollapse(index)" class="cursor-pointer">
                      <span>Дополнительная информация</span>
                      <v-icon>{{ showDetails[index] ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                    </div>

                    <!-- Блок с дополнительной информацией, который показывается/скрывается по клику на кнопку -->
                    <div v-if="showDetails[index]">

                      <v-row class="py-2 mt-2">
                        <v-col cols="2" class="column is-3">
                          <img src="/assets/road.svg">
                        </v-col>
                        <v-col cols="10" class="column is-9 order-route">
                          <v-row>
                            <h1 class="title is-5">{{ item.order.from_location }}</h1>
                          </v-row>
                          <v-row>
                            <h1 class="title is-5 mt-2">{{ item.order.to_location }}</h1>
                          </v-row>
                        </v-col>
                      </v-row>

                      <div v-if="item.order.car_id">
                        <h3 class="title pt-2 is-6">Машина</h3>
                        <p>{{ wrapperCar(item.order) }}</p>
                      </div>

                      <div v-if="item.order.comment && item.order.comment !== ''">
                        <h3 class="title is-6">Комментарий</h3>
                        <p class="mb-0 text-pre-wrap">{{ item.order.comment }}</p>
                      </div>

                      <h3 class="title is-6">Ваш комментарий</h3>
                      <p class="text-pre">{{item.comment}}</p>
                    </div>

                  </div>
                </v-card-text>
              </v-card>

            </v-row>
          </v-container>


        </v-container>
      </v-main>
    </div>
  </div>

</template>

<script>

export default {
  name: 'OrderListTransporter',
  data() {
    return {
      variant: 'outlined',
      showDetails: [],
      status_orders: {
        0: 'Отправлено',
        1: 'Согласовано',
        2: 'Состоялось',
      }
    };
  },
  created() {
    this.$store.dispatch('GET_OFFERS_HISTORY');
  },
  computed: {
    offers() {
      return this.$store.getters.OFFERS_HISTORY.offers;
    },
  },
  methods: {
    wrapperCar(order) {
      if (order.car.mark && order.car.model) {
        return order.car.mark + ' ' + order.car.model
      } else {
        return '';
      }
    },
    toggleCollapse(index) {
      // Изменяем состояние сворачивания для соответствующей карточки
      this.$set(this.showDetails, index, !this.showDetails[index]);
    },
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
    goBack() {
      this.$router.push('/activity-feed');
    },
    formatText(text) {
      let text_formated = text ? text.replace(/\n/g, '<br>') : '';
      return text_formated;
    },
  },
};
</script>

<style scoped>
.title.is-6 {
  font-size: 1.2em !important;
  margin-bottom: 0 !important;
}
</style>
